import { Link, navigate } from "gatsby";
import React from "react";

import PageWrapper from "../components/PageWrapper";

const Faq = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          footerStyle: "style2",
        }}
      >
        <div className="pt-23 pt-md-26 pt-lg-31 pb-13 pb-md-18 pb-lg-23">
          <div className="container">
            {/* Section Title */}
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-9">
                <div className="text-center mb-13 mb-lg-18">
                  <h2 className="font-size-11 mb-7">Preguntas Frecuentes</h2>
                  <p className="font-size-7 mb-0 px-md-10 px-xl-13">
                    Contigo siempre seremos claros y transparentes
                  </p>
                </div>
              </div>
            </div>
            {/* End Section Title */}
            {/* Faqs */}
            {/* Faqs */}
            <div className="row justify-content-center">
              {/* Single Faq */}
              <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="mr-6">
                    <div className="bg-light-orange circle-28 text-white mt-1">
                      <i className="fa fa-question" />
                    </div>
                  </div>
                  <div className="">
                    <h4 className="font-size-7 mb-7">
                      ¿Podré reembolsar el exámen con Fonasa o Isapres?
                    </h4>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                      Sí! Como parte del servicio que brindamos nos encargaremos
                      de que tengas una órden médica, requisito para el
                      reembolso.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Faq */}
              {/* Single Faq */}
              <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="mr-6">
                    <div className="bg-light-orange circle-28 text-white mt-1">
                      <i className="fa fa-question" />
                    </div>
                  </div>
                  <div className="">
                    <h4 className="font-size-7 mb-7">
                      ¿Cuánto se demora el resultado?
                    </h4>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                      Tendrás tu resultado en 12 hrs hábiles desde la toma de
                      muestra.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Faq */}
              {/* Single Faq */}
              <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="mr-6">
                    <div className="bg-light-orange circle-28 text-white mt-1">
                      <i className="fa fa-question" />
                    </div>
                  </div>
                  <div className="">
                    <h4 className="font-size-7 mb-7">
                      ¿Cuál es el precio de un test?
                    </h4>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                      El precio por test es de $45.000, lo cual incluye el
                      exámen ($25.000) y costos de toma de muestra, transporte e
                      insumos ($20.000). Hacemos descuento por grupos con más
                      personas.
                      <Link to="/#precios">Revisa nuestros precios aquí</Link>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Faq */}
              {/* Single Faq */}
              <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="mr-6">
                    <div className="bg-light-orange circle-28 text-white mt-1">
                      <i className="fa fa-question" />
                    </div>
                  </div>
                  <div className="">
                    <h4 className="font-size-7 mb-7">
                      ¿En qué horario trabajan?
                    </h4>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                      Tomamos muestras de lunes a viernes entre las 8:00 y 13:00
                      horas. Si necesitas un test de emergencia fuera de este
                      horario, contáctanos lo más pronto posible.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Faq */}
              {/* Single Faq */}
              <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="mr-6">
                    <div className="bg-light-orange circle-28 text-white mt-1">
                      <i className="fa fa-question" />
                    </div>
                  </div>
                  <div className="">
                    <h4 className="font-size-7 mb-7">
                      ¿Qué debo hacer si mi test resulta positivo?
                    </h4>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                      Debes aislarte por un mínimo de 11 días y comunicarle a
                      tus contactos estrechos, los cuales deben hacer cuarentena
                      de 14 días. Para más información, recomendamos ver la{" "}
                      <a href="https://saludresponde.minsal.cl/preguntas-frecuentes-covid19">
                        información dispuesta por el Ministerio de Salud
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Faq */}
              {/* Single Faq */}
              <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="mr-6">
                    <div className="bg-light-orange circle-28 text-white mt-1">
                      <i className="fa fa-question" />
                    </div>
                  </div>
                  <div className="">
                    <h4 className="font-size-7 mb-7">
                      ¿Qué debo hacer si mi test es positivo y necesito licencia
                      médica?
                    </h4>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                      Debes ver a un médico tratante para ser examinado y
                      evaluado completamente. Tu médico podrá emitir tu licencia
                      médica.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Faq */}
              {/* Single Faq */}
              <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="mr-6">
                    <div className="bg-light-orange circle-28 text-white mt-1">
                      <i className="fa fa-question" />
                    </div>
                  </div>
                  <div className="">
                    <h4 className="font-size-7 mb-7">
                      ¿Puedo circular normalmente una vez terminado el período
                      de aislamiento?
                    </h4>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                      Sí. En caso de requerir un certificado de alta, consulta
                      con tu médico.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Faq */}
              {/* Single Faq */}
              <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="mr-6">
                    <div className="bg-light-orange circle-28 text-white mt-1">
                      <i className="fa fa-question" />
                    </div>
                  </div>
                  <div className="">
                    <h4 className="font-size-7 mb-7">
                      ¿Cuánto tiempo pueden durar los resultados positivos?
                    </h4>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                      Estudios muestran que tests PCR pueden salir positivos más
                      de 3 meses después del primer diagnóstico.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Faq */}
              {/* Single Faq */}
              {/* <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="mr-6">
                    <div className="bg-light-orange circle-28 text-white mt-1">
                      <i className="fa fa-question" />
                    </div>
                  </div>
                  <div className="">
                    <h4 className="font-size-7 mb-7">
                      ¿Cuál es el márgen de error del test?
                    </h4>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                      Estudios muestran que tests PCR pueden salir positivos más
                      de 3 meses después del primer diagnóstico.
                    </p>
                  </div>
                </div>
              </div> */}
              {/* End Single Faq */}
            </div>
            {/* Button  */}
            <div className="text-center pt-lg-3">
              <p className="font-size-6 mb-0">
                ¿Tienes más preguntas?
                <Link
                  to={"/contacto"}
                  className="btn btn-dodger-blue-2 rounded-5 mx-10"
                >
                  Contáctanos
                </Link>
              </p>
            </div>
            {/* Button End */}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Faq;
